import { Swiper, Navigation, A11y, Pagination  } from 'swiper/swiper.esm.js';

Swiper.use([Navigation, A11y, Pagination]);

import Component from "../../../base/Component";

export default class LandingDrylokSika extends Component {
    constructor(props) {
        super(props);
        this.slider = document.querySelector('[data-landingdryloksika-slider]');
        this.bullets = document.querySelectorAll('.slider-bar .bullet'); 
    }

    init() {
        if (!this.slider) {
            return;
        }
        
        this.swiper = new Swiper(this.slider.querySelector('.swiper-container'), { 
            touchRatio: 1,
            slidesPerView: 1,
            spaceBetween: 0,
            autoHeight: true,
            loop: false, 
            breakpoints: {
                400: { slidesPerView: 1, spaceBetween: 10 },
                768: { slidesPerView: 1, spaceBetween: 10 },
                1024: { slidesPerView: 1, spaceBetween: 30 },
                1200: { slidesPerView: 1, spaceBetween: 75 },
            },
            pagination: {
                el: '.swiper-pagination',
                clickable: true, 
                renderBullet: (index, className) => {
                    const slide = this.bullets[index]; 
                    if (!slide) return '';

                    return `<div class="${className} bullet w-[262px] relative flex flex-col gap-[8px] text-center">
                        ${slide.innerHTML} 
                    </div>`;
                }
            },
            on: {
                init: () => {
                    this.updatePagination();
                },
                slideChange: () => {
                    this.updatePagination();
                },
                click: () => {
                    this.updatePagination(); 
                }
            },
        });
    }
    
    updatePagination() {
        const pagination = document.querySelector('.swiper-pagination'); 

        const bullets = pagination.querySelectorAll('.swiper-pagination-bullet');

        bullets.forEach((bullet, index) => {
            const isActive = index === this.swiper.activeIndex; 
            const isPassed = index < this.swiper.activeIndex;

            if (index === this.swiper.slides.length - 1 && this.swiper.realIndex === this.swiper.slides.length - 1) {
                bullet.classList.add('passed');
            } else {
                bullet.classList.toggle('active', isActive);   
                bullet.classList.toggle('passed', isPassed); 
            }
        });
    }
}
