import Component from "../base/Component";

export default class VideoLogic extends Component {

    init() {
        const playButton = document.querySelector(".play-button");
        const thumbnail = document.querySelector(".video-thumbnail");
        const iframe = document.querySelector(".youtube-video");

        playButton.addEventListener("click", function () {
            if (iframe) {
                iframe.src = iframe.getAttribute("src") + "&autoplay=1"; 
                iframe.classList.remove("hidden"); 
                playButton.classList.add("hidden");
                if(thumbnail) {
                    thumbnail.classList.add("hidden");
                }
            }
        });
    }
}