import Component from "../base/Component";

export default class Faqs extends Component {

    init() {
        document.querySelectorAll(".faq-title").forEach(title => {
            title.addEventListener("click", function() {
                const content = this.nextElementSibling; 
                const toggle = this.querySelector(".faq-toggle");

                if (content.style.maxHeight && content.style.maxHeight !== "0px") {
                    content.style.maxHeight = "0px";
                    content.style.marginBottom = "0px"; 
                    toggle.textContent = "↓";
                } else {
                    content.style.maxHeight = content.scrollHeight + "px";
                    content.style.marginBottom = "24px";
                    toggle.textContent = "✕";
                }
            });
        });
    }
}