import Component from "../base/Component";

export default class HeaderDrylokSika extends Component {

    init() {
        const header = document.querySelector(".header");

        window.addEventListener("scroll", function () {
            if (window.scrollY > 100) {
                header.style.background = "#2F74A4"; 
            } else {
                header.style.background = "none";
            }
        });
    }
}